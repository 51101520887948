import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from "firebase/auth";
import {
  getArtistSongs,
  postArtistSongs,
  putArtistSongs,
  getArtistScores,
  postArtistScore,
} from "../endpoints/api";
import useAsset from "../hooks/useAsset";
import Headphones from "../assets/images/Group 1000006010.svg";
import { usePostHog } from "posthog-js/react";
import VictoryAudio from "../assets/audio/a37ca98e-4559-4a5f-a673-f53b5c65f99c.mp3";
import loseAudio from "../assets/audio/b86f3d06-914b-47ff-a75d-8db9631532d8.mp3";
import Confetti from "react-confetti";
import { generateUsername } from "friendly-username-generator";
import useClevertap from "../hooks/useClevertap";
import Header from "../components/Header";
import ScoreCard from "../components/ScoreCard";
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID } from "../constants/dataConstant";

const zero = [
  "Let's try this again!",
  "Hit reset and crush this time?",
  "Let’s bounce back. Try again",
  "Time for a comeback?",
];
const one = [
  "Don't give up!",
  "Brush off, bounce back",
  "Keep vibing, don’t trip",
  "Stay in the game, you got this!",
];
const two = [
  "You can do better.",
  "Level up, you got more in you!",
  "Try again, it’s on you",
  "You got more in the tank, replay?",
];
const three = [
  "Better luck next time.",
  "On to the Next, You're Gonna Ace It!",
  "Brush it Off, Next Round's Yours!",
  "Next Time's Your Time!",
];
const four = [
  "Keep trying!",
  "Keep Hustling, You're on the Grind!",
  "Keep pushing; success is the vibe.",
  "No cap, the grind never stops",
];
const five = [
  "Room for improvement.",
  "Let's Level It Up!",
  "Time to Glow Up, always room to shine",
  "You're on the rise",
];
const ten = [
  "Outstanding!",
  "You're a Legend!",
  "OMG, you aced it!",
  "Boss Level: Flawless Victory",
];
const six = [
  "Decent effort!",
  "Solid moves on this one!",
  "Keep crafting your story!",
  "You’re on the map, go on!",
];
const seven = [
  "Good job!",
  "Killing the Game: Major Vibes!",
  "Vibes on Point: Keep the Energy!",
  "Game Strong: Keep going!",
];
const eight = [
  "Very good!",
  "Vibes on Fleek: You're Nailing It!",
  "Lowkey Killing It, Highkey Impressive!",
  "Highkey Impressive: You're Killing the Game!",
];
const nine = [
  "Excellent!",
  "Straight Fire: You're Absolutely Killing It!",
  "Lit Levels: You're Slaying, Keep It Up!",
  "Boss vibes only!",
];

const ScoreScreen = () => {
  const posthog = usePostHog();

  const [showAuth, setShowAuth] = useState(false);
  const [buttonText, setButtonText] = useState("Sign up");
  // const [user, setUser] = useState<User | null>(null);
  const [artistImage, setArtistImage] = useState("");
  const [artistName, setArtistName] = useState("");
  const [quizData, setQuizData] = useState([]);
  const { id } = useParams();
  // const rightAnswer = parseInt(right);
  const [right, setRight] = useState(null);
  const [time, setTime] = useState(null);
  const [randomUsername, setRandomUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [text, setText] = useState("");
  const [artistScores, setArtistScores] = useState([]);
  const [percentile, setPercentile] = useState(0);
  const [userClaimNft, setUserClaimNft] = useState(false);
  const [rightLoader, setRightLoader] = useState(true);
  const [sentPopup, setSentPopup] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const location = useLocation();
  // console.log("LOCATION ", location);

  const {
    getUser,
    accessToken,
    setAccessToken,
    user,
    setUser,
    userInfo,
    createUser,
    claimNft,
  } = useAsset();
  const navigate = useNavigate();
  const auth = getAuth();
  const { handleEventPushClick } = useClevertap();

  let copyToClipboard = () => {
    const textToCopy = document.getElementById("textToCopy")?.textContent || "";
    navigator.clipboard.writeText(textToCopy);
  };

  const textDisplay = (rightAnswer) => {
    if (quizData?.length) {
      if (rightAnswer === 0) {
        setText(zero[Math.floor(Math.random() * zero.length)]);
      } else if (rightAnswer === 10) {
        setText(one[Math.floor(Math.random() * one.length)]);
      } else if (rightAnswer === 20) {
        setText(two[Math.floor(Math.random() * two.length)]);
      } else if (rightAnswer === 30) {
        console.log("im being called");
        setText(three[Math.floor(Math.random() * three.length)]);
      } else if (rightAnswer === 40) {
        setText(four[Math.floor(Math.random() * four.length)]);
      } else if (rightAnswer === 50) {
        setText(five[Math.floor(Math.random() * five.length)]);
      } else if (rightAnswer === quizData.length * 10) {
        setText(ten[Math.floor(Math.random() * ten.length)]);
      } else if (rightAnswer === 60) {
        setText(six[Math.floor(Math.random() * six.length)]);
      } else if (rightAnswer === 70) {
        setText(seven[Math.floor(Math.random() * seven.length)]);
      } else if (rightAnswer === 80) {
        setText(eight[Math.floor(Math.random() * eight.length)]);
      } else if (rightAnswer === 90) {
        setText(nine[Math.floor(Math.random() * nine.length)]);
      } else {
        return "Well done";
      }
    }
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handlePlayAgain = () => {
    posthog?.capture("start_over", {
      email: userInfo?.email,
    });
    handleEventPushClick("MUSICNERD_START_OVER", {
      email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
    });
    navigate(`/quiz/${id}`, {
      state: {
        artistName: artistName,
        artistImage: artistImage,
      },
    });
  };

  const handleWebPopup = (artist_name) => {
    console.log("web_popup_clicked");
    handleEventPushClick("MUSICNERD_ARTIST_QUIZ_RESPONDED", {
      click: "web_popup_clicked",
      artistName: artist_name,
      artistId: id,
    });
  };

  useEffect(() => {
    if (artistName && sentPopup === false) {
      document.title = `${artistName} | Musicnerd - Name that tune`;
      handleWebPopup(artistName);
      setSentPopup(true);
      let firstTimePlay = localStorage.getItem("firstTimePlay");
      if (firstTimePlay === null || firstTimePlay === "true") {
        posthog?.capture("artist_quiz_started", {
          artist_id: id,
          artist_name: location.state?.artistName,
        });
        localStorage.setItem("firstTimePlay", "false");
      }
      setTimeout(() => {
        console.log("MUSICNERD_WEBPOPUP_2");
        handleEventPushClick("MUSICNERD_WEBPOPUP_2", {
          page: "artist_page",
          artistName: artistName,
          artistId: id,
        });
      }, 3000);
    }
  }, [artistName]);

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.uid, {
        email: user.email,
      });
      posthog?.capture("login", {
        email: user.email,
      });
      console.log("EVENT SENT ");
    }
  }, [posthog, user?.uid, user?.email]);

  useEffect(() => {
    if (userInfo?.email && artistName) {
      let getFirstTime = localStorage.getItem("firstTime");
      console.log("GETFIRST TIME ", getFirstTime);
      if (getFirstTime === null) {
        posthog?.capture("signup", {
          email: userInfo?.email,
        });
        console.log({
          email: userInfo?.email,
          artistName: artistName,
        });
        handleEventPushClick("MusicNerd_AccountCreated", {
          username: userInfo?.username,
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artistName: artistName,
        });
        handleEventPushClick("MUSICNERD_ACCOUNT_CREATED", {
          username: userInfo?.username,
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artistName: artistName,
        });
        console.log("Email sent", userInfo?.email);
        // const body = {
        //   email: userInfo?.email,
        //   artist_name: artistName ? artistName : "Music Fan",
        // };
        // fetch(`${process.env.REACT_APP_URL}/send-email`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(body),
        // })
        //   .then(() => {
        //     console.log("Email sent");
        //   })
        //   .catch((err) => {
        //     console.log(err?.message);
        //   });
        localStorage.setItem("firstTime", "false");
      } else {
        posthog?.capture("login", {
          email: userInfo?.email,
        });
      }
    }
  }, [userInfo, artistName]);

  useEffect(() => {
    if (accessToken) {
      getUser();
    }
  }, [accessToken]);

  const victoryAudio = new Audio(VictoryAudio);

  useEffect(() => {
    if (right) {
      textDisplay(parseInt(right));
    }
  }, [right, quizData?.length]);

  useEffect(() => {
    if (parseInt(right) === 100) {
      victoryAudio.play();
      setTimeout(() => {
        setShowConfetti(true);
      }, 1000);

      setTimeout(() => {
        setShowConfetti(false);
      }, 6000);
    } else if (parseInt(right) === 0) {
      const LoseAudio = new Audio(loseAudio);
      LoseAudio.play();
    }
  }, [right]);

  useEffect(() => {
    if (userClaimNft && userInfo) {
      console.log("CLAIMING NFT 2");
      claimNft(
        userInfo?.uuid,
        artistName,
        id,
        artistImage,
        0,
        right,
        time,
        percentile?.toFixed(2),
        userInfo?.wallet
      );
      setUserClaimNft(false);
    }
  }, [userClaimNft, userInfo]);

  const handleGoogleSignIn = async () => {
    clevertap.setLogLevel(3);
    clevertap.init(CLEVERTAP_ACCOUNT_ID);

    setSaveLoading(true);
    const provider = new GoogleAuthProvider();

    if (accessToken && accessToken !== "") {
      try {
        setButtonText("Signing ...");

        const old_username = localStorage.getItem("username");
        const body = {
          old_username:
            old_username?.length > 0 ? old_username : randomUsername,
          username: userInfo.username,
        };
        console.log("1 PUT BODY ", body);
        await putArtistSongs(body, accessToken);
   
        handleEventPushClick("MUSICNERD_SIGNUP", {
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artist: artistName,
        });
        const postScoreData = async () => {
          const body = {
            username: userInfo?.username,
            score: parseInt(right),
            artist_id: id,
            artist_name: artistName,
            artist_image: artistImage,
            time_taken: parseInt(time),
            percentile: parseInt(percentile.toFixed(1)),
          };
          console.log("2 BODY ", body);
          await postArtistScore(body, accessToken);
        };
        postScoreData();
        console.log("FIRST CLAIM");
        await claimNft(
          userInfo?.uuid,
          artistName,
          id,
          artistImage,
          0,
          right,
          time,
          percentile?.toFixed(2),
          userInfo?.wallet
        );
        localStorage.setItem("loggedIn", "true");
        let firstTime = localStorage.getItem("firstTime");
        // console.log("firstTime in 1", firstTime);
        if (firstTime === "false") {
          await sleep(3000);
          if (window.location.href.includes("musicnerd.io")) {
            navigate(`/leaderboard/${id}`, {
              state: { score: right, time: time, percentile: percentile },
            });
          } else {
            navigate(`/quiz/leaderboard/${id}`, {
              state: { score: right, time: time, percentile: percentile },
            });
          }
        }
      } catch (e) {
        console.log("error", e);
      } finally {
        setSaveLoading(false);
      }
    } else {
      try {
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
        clevertap.profile.push({
          "Site": {
            "Name": result.user?.displayName,               
            "Email": result.user?.email,
          }
        });
        console.log(result.user);

        if (result.user) {
          try {
            const old_username = localStorage.getItem("username");
            const body = {
              old_username:
                old_username?.length > 0 ? old_username : randomUsername,
              username: result.user.displayName,
            };

            let checkIfReferralUsed = localStorage.getItem("referralCodeUsed");
            if (checkIfReferralUsed === null) {
              let ref = localStorage.getItem("ref");
              console.log("Referral code", ref);
              const body = {
                referralCode: ref,
                referreduser: result.user.email,
              };
              fetch(`https://cruncher.asset.money/misc/referral`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
              })
                .then((response) => {
                  console.log(response);
                  localStorage.setItem("referralCodeUsed", "true");
                })
                .catch((err) => {
                  console.log(err?.message);
                });
            }

            console.log("2 PUT BODY ", body);
            result.user.getIdToken().then(async (token) => {
              console.log("TOKEN ", token);
              result.user.getIdToken().then(async (token) => {
                await putArtistSongs(body, token);
                handleEventPushClick("MUSICNERD_SIGNUP", {
                  email: result.user?.email ? result.user.email : "inboxdev.b@gmail.com",
                  artist: artistName,
                });
                setAccessToken(token);
                localStorage.setItem("loggedIn", "true");
              });

              const postScoreData = async () => {
                const body = {
                  username: result.user.displayName,
                  score: parseInt(right),
                  artist_id: id,
                  artist_name: artistName,
                  artist_image: artistImage,
                  time_taken: parseInt(time),
                  percentile: parseInt(percentile.toFixed(1)),
                };
                await postArtistScore(body, token);
              };
              postScoreData();
              let getFirstTime = localStorage.getItem("firstTime");
              console.log(
                "getFirstTime in 2",
                getFirstTime,
                result.user.email,
                artistName
              );
              if (result.user.email && artistName) {
                if (getFirstTime === null) {
                  posthog?.capture("signup", {
                    email: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
                  });
                  console.log({
                    email: result.user.email,
                    artistName: artistName,
                  });
                  handleEventPushClick("MusicNerd_AccountCreated", {
                    username: result.user.displayName,
                    email: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
                    artistName: artistName,
                  });
                  handleEventPushClick("MUSICNERD_ACCOUNT_CREATED", {
                    username: result.user.displayName,
                    email: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
                    artistName: artistName,
                  });
                  console.log("Email sent", result.user.email);
                  // const body = {
                  //   email: result.user.email,
                  //   artist_name: artistName ? artistName : "Music Fan",
                  // };
                  // fetch(`${process.env.REACT_APP_URL}/send-email`, {
                  //   method: "POST",
                  //   headers: {
                  //     "Content-Type": "application/json",
                  //   },
                  //   body: JSON.stringify(body),
                  // })
                  //   .then(() => {
                  //     console.log("Email sent");
                  //   })
                  //   .catch((err) => {
                  //     console.log(err?.message);
                  //   });
                  localStorage.setItem("firstTime", "false");
                }
              }
              setUserClaimNft(true);
              await sleep(5000);
              if (window.location.href.includes("musicnerd.io")) {
                navigate(`/leaderboard/${id}`, {
                  state: { score: right, percentile: percentile },
                });
              } else {
                navigate(`/quiz/leaderboard/${id}`, {
                  state: { score: right, percentile: percentile },
                });
              }
              setAccessToken(token);
            });
          } catch (e) {
            console.log("error", e);
          }
        }
        setShowAuth(false);
      } catch (error) {
        console.error("Google Sign-In Error:", error);
      } finally {
        setSaveLoading(false);
      }
    }
  };
  const getArtistData = async (id) => {
    const response = await getArtistSongs(id);
    // console.log("RESPONSE ", response.data.data);
    // console.log("LOCATION ", location.state)
    console.log(
      "location.state?.artist_image",
      location.state?.artist_image,
      response.data.data.artist_image
    );
    console.log(
      "location.state?.artist_name",
      location.state?.artist_name,
      response.data.data.artist_name
    );
    setArtistImage(
      location.state?.artist_image
        ? location?.state?.artist_image
        : response.data.data.artist_image
    );
    setArtistName(
      location.state?.artist_name
        ? location.state.artist_name.charAt(0).toUpperCase() +
            location.state.artist_name.slice(1)
        : response?.data?.data?.artist_name
    );
    setQuizData(response?.data?.data?.artist_quiz);
  };

  useEffect(() => {
    getArtistData(id);
  }, [id]);

  useEffect(() => {
    if (quizData?.length > 0) {
      if (location.state?.artist_name?.length > 0) {
        setArtistName(location.state.artist_name);
      }

      if (location.state?.artist_image?.length > 0) {
        setArtistImage(location.state.artist_image);
      }

      if (location?.state?.right) {
        setRight(location.state.right);
      }

      if (location?.state?.time) {
        setTime(location.state.time);
      }
    }
  }, [location.state?.artist_name, location.state?.artist_image, quizData]);

  useEffect(() => {
    let loggedIn = localStorage.getItem("loggedIn");
    // console.log("artistName ", artistName);
    // console.log("artistName ", typeof artistName);

    // console.log("loggedIn ", loggedIn);
    // console.log("userinfo ", userInfo);
    if (typeof artistName === "string" && artistName.length > 0) {
      if (loggedIn) {
        if (!savedData && userInfo?.username?.length > 0) {
          // console.log("ACCESS TOKEN ", accessToken);
          // console.log("USER INFO ", userInfo);
          if (
            typeof userInfo?.username === "string" &&
            userInfo?.username.length > 0
          ) {
            console.log("user logged in");
            const postData = async () => {
              // console.log("userInfo 1 ", userInfo);
              const body = {
                uuid: userInfo?.uuid,
                username: userInfo?.username,
                score: parseInt(right),
                artist_id: id,
                artist_name: artistName,
                artist_image: artistImage,
                time: parseInt(time),
                logged: true,
              };
              console.log("2 BODY ", body);
              await postArtistSongs(body, accessToken);
            };

            postData();
            setSavedData(true);
          }
        }
      } else {
        if (!savedData) {
          let savedUsername = localStorage.getItem("username");

          if (typeof savedUsername !== "string" || savedUsername.length === 0) {
            savedUsername = generateUsername(); // Generate a username of 10 characters
            localStorage.setItem("username", savedUsername);
          }
          setRandomUsername(savedUsername);

          // Define async function
          const postData = async () => {
            const body = {
              uuid: "",
              username: savedUsername,
              score: parseInt(right),
              artist_id: id,
              artist_name: artistName,
              artist_image: artistImage,
              time: parseInt(time),
              logged: false,
            };
            console.log("1 BODY ", body);
            await postArtistSongs(body);
          };

          postData();
          setSavedData(true);
        }
      }
    }
  }, [userInfo, artistName]);

  const getScores = async (id) => {
    const response = await getArtistScores(id);
    setArtistScores(response.data.data);
  };

  useEffect(() => {
    // Fetch the data when the component mounts
    getScores(id);
  }, [id]);

  function calculatePercentile(artistScores, userScore, userTime) {    
    if (
      !artistScores || 
      artistScores.length <= 1
    ) {
      return 0;
    }
  
    // console.log('artistScores are ', artistScores)
    let userEfficiency = userScore / userTime;
  
    // console.log('userEfficiency is ', userEfficiency)
    // Calculate efficiencies for artist scores
    let efficiencies = artistScores.map((obj) => {
      let efficiency = obj.score / obj.time;
      return isNaN(efficiency) ? 0 : efficiency;
    });
  
    // Add user's efficiency to the array
    efficiencies.push(userEfficiency);
  

    // Sort efficiencies in descending order
    efficiencies.sort((a, b) => b - a);
    
  console.log('efficiencies are ', efficiencies.length)
    // Calculate the user's rank in efficiencies
    let rank = efficiencies.indexOf(userEfficiency) + 1;
    console.log('rank is ', rank)
    let percentile = (rank / efficiencies.length) * 100;
  
    // Round to 2 decimal places, ensuring it's not 100%
    percentile = Math.min(Math.round(percentile * 100) / 100, 99.99);
  
    return percentile;
  }

  useEffect(() => {
    setPercentile(calculatePercentile(artistScores, right, time));
  }, [artistScores, time]);

  useEffect(() => {
    setTimeout(() => {
      setRightLoader(false);
    }, 2000);
  }, []);

  if (!right && !time && !rightLoader) {
    return (
      <div className="flex flex-col">
        <div className="bg-blue flex flex-col w-screen h-screen mt-7 gap-4 items-center">
          <div className="flex items-center">
            <Link to="/">
              <div className="flex flex-row gap-2 py-4">
                <img src={Headphones} alt="headphones" className="w-8" />
                <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                  MUSIC NERD
                </span>
              </div>
            </Link>
          </div>
          <div className="flex flex-col justify-center gap-3 items-center min-h-[80vh] text-white">
            <h3 className="text-7xl text-extrabold text-[rgba(239,209,101,1)]">
              PAGE NOT FOUND
            </h3>
            <Link to="/" className="text-[rgba(239,209,101,1)]">
              Go back to the homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }

console.log('percentile is', percentile)

  return (
    <div className="flex flex-col">
      {/* <Header /> */}
      <div className="bg-blue flex flex-col w-screen h-screen md:mt-7 mt-3 gap-4 items-center">
        {showConfetti && <Confetti />}
        <div className="flex items-center">
          <Link to="/">
            <div className="flex flex-row gap-2 py-4">
              <img src={Headphones} alt="headphones" className="w-8" />
              <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                MUSIC NERD
              </span>
            </div>
          </Link>
          {accessToken && (
            <div className="flex justify-end mr-5 md:mr-10 absolute right-0">
              <Link to="/profile">
                <img
                  src={userInfo?.profilepic}
                  alt=""
                  className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-gray-800"
                />
              </Link>
            </div>
          )}
        </div>
        {loading ? (
          <div className="flex flex-col justify-center items-center">
            <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
              Loading...
            </span>
          </div>
        ) : (
          <div>
            <div className="flex flex-col justify-center gap-3 items-center">
              <p className="md:text-5xl text-2xl md:px-0 px-4 text-center text-[rgba(239,209,101,1)] cabinet-grotesk font-bold">
                {text}
              </p>
              <p className="md:text-xl text-lg text-center text-white cabinet-grotesk font-extrabold">
              Your fandom score-card
              </p>
              <ScoreCard
                right={right / 10}
                artistName={artistName}
                time={time}
                artistImage={artistImage}
                percentile={percentile}
              />
              <div className="flex flex-col mb-3 items-center justify-center">
                <p className=" text-[rgba(203,203,203,1)] font-bold md:text-base cabinet-grotesk md:px-20">
                  {accessToken ? "Save" : "Sign up"} to see fan leaderboard
                </p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center gap-5 mb-12">
              <button
                className="bg-[rgba(239,209,101,1)] text-black inter w-80 md:w-72 h-12 rounded-full"
                onClick={handleGoogleSignIn}
                disabled={saveLoading}
              >
                <p className="cabinet-grotesk text-base font-bold">
                  {saveLoading
                    ? "Saving..."
                    : accessToken
                    ? "Save Score"
                    : buttonText}

                  {/* Claim Score Card */}
                </p>
              </button>
              <button
                className="border-[rgba(239,209,101,1)] border-2 inter w-80 md:w-72 h-12 rounded-full"
                onClick={handlePlayAgain}
              >
                <p className="cabinet-grotesk font-bold text-base text-[rgba(239,209,101,1)]">
                  Start Over
                </p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreScreen;
