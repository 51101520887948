import axios from 'axios';
import axiosApiInstance from '../config/axios';

const userUrl = 'api/v1';

const baseURL = process.env.REACT_APP_ASSET_API;


export const getData = () => {
    return axiosApiInstance.get(`${userUrl}/data`)
};

export const searchArtist = (artistName: string) => {
    return axios.get(`${baseURL}/${userUrl}/music/artist/search/${artistName}`)
    // https://users.stage.asset.money/api/v1/music/artist/search/dra
}

export const getArtistSongs = (artistId: string) => {
    return axios.get(`${baseURL}/${userUrl}/music/artist/quiz/${artistId}`)
}

export const getLeaderboard = (artistId: string) => {
    return axios.get(`${baseURL}/${userUrl}/user/leaderboard/${artistId}`)
}

export const putArtistSongs = (body: any, accessToken: any) => {
    // console.log("accessToken inside the api", accessToken)
    return axios.put(`${baseURL}/${userUrl}/user/leaderboard`,
    body,
    {
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
      })
}
export const postArtistSongs = (body: any) => {
    return axios.post(`${baseURL}/${userUrl}/user/leaderboard`,
    body,
    {
        headers: {
          "Content-Type": "application/json",
        },
      })
}

export const getGlobalLeaderboard = () => {
  return axios.get(`${baseURL}/${userUrl}/user/artist/leaderboard`)
}

export const getArtistScores = (artistId: string) => {
  return axios.get(`${baseURL}/${userUrl}/user/artist/scores/${artistId}`)
}

export const postArtistScore = (body: any,accessToken: any) => {
  return axios.post(`${baseURL}/${userUrl}/user/badges`,
  body,
  {
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/json",
      },
    })
}
