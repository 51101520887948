import React, { useEffect } from 'react'
import axios from 'axios'


const Offers = () => {

    // redirect to offers.musicnerd.io
    useEffect(() => {
        // get query params
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');

        const userReferralUsed = localStorage.getItem('referralCodeUsed');
        if (userReferralUsed) {
            window.location.href = 'https://offers.musicnerd.io'
        }
        // call the referral api
        axios.post('https://cruncher.asset.money/misc/referral', {
            referralCode: ref
        })
        .then(function (response) {
            console.log(response);
            localStorage.setItem('referralCodeUsed', 'true');
            window.location.href = 'https://offers.musicnerd.io'
        })
    }, [])

  return (
    <div className='flex items-center justify-center min-h-screen'>
        {/* Activity Indicator taiwlind */}
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
    </div>
  )
}

export default Offers