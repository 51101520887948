import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { generateUsername } from 'friendly-username-generator'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import useClevertap from './useClevertap';

// const auth = getAuth();

const useAsset = () => {

    const [accessToken, setAccessToken] = useState("");
    const [userData, setUserData] = useState({});
    const [userId, setUserId] = useState("");
    const [userCreated, setUserCreated] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [username, setUsername] = useState("");
    const [user, setUser] = useState(null);
    const { onUserLogin } = useClevertap()



    useEffect(() => {
        const auth = getAuth();
        // Check if user is already logged in
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            // console.log("USER ", user)
            setUserData(user);
            user.getIdToken().then((token) => {
              // console.log("TOKEN ", token)
              setAccessToken(token);
            });
          } else {
            setAccessToken(null);
          }
        });
    
        return () => unsubscribe(); // Cleanup the event listener on unmount
      }, []);

      useEffect(() => {
        if (accessToken && accessToken.length > 0) {
          getUser();
        }
      }, [accessToken])

      function googleLogout() {
        const auth = getAuth();
    
        // Sign out the currently authenticated user
        auth
          .signOut()
          .then(() => {
            setAccessToken("");
            // console.log("Successfully logged out");
            localStorage.setItem("loggedIn", false);
            window.location.href = "/"; // Reload to the main domain
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
      }
      const getUser = async () => {
        if ((accessToken && accessToken.length > 0)) {
          // setClaimState("claiming");
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`,
              {
                headers: {
                  Authorization: accessToken,
                  "Content-Type": "application/json",
                },
              }
            );
            if (response && response.data) {
              // console.log('inside the resp')
              localStorage.setItem("loggedIn", true);
              setUserId(response.data.data.uuid)
              setUsername(response.data.data.username)
              setUserInfo(response.data.data)
              // console.log('username is', response.data.data.username)

              setUserCreated(true);
              onUserLogin({
                email: response?.data?.data?.email ? response?.data?.data?.email : "inboxdev.b@gmail.com",
                name: response?.data?.data?.firstname,
              })
              // createCollectible();
            }
            // return response.data;
          } catch (error) {
            console.log(error);
    
            if (error.response && error.response.status === 401) {
              // Call the createUser function here
              await createUser();
            } else {
              console.log(error);
            }
          }
        } else {
          console.log("no access token");
        }
      };

  // const generateUsername = (username, uuid) => {
  //   // Remove spaces and special characters from the username
  //   const cleanUsername = username.replace(/[^a-zA-Z0-9]/g, "");

  //   // Get the last three characters of the uuid
  //   const lastThreeCharacters = uuid.slice(-3);

  //   const modifiedUsername = cleanUsername + lastThreeCharacters;

  //   return modifiedUsername;
  // };

  const createUser = async () => {
    // localStorage.setItem("askForUsername", true);
    //write a condition to ensure there is no user with same username
    const firebaseUser = getAuth().currentUser;
    if (firebaseUser !== null) {
      // const username = generateUsername(
      //   firebaseUser.displayName,
      //   firebaseUser.uid
      // );
      const username = generateUsername()
      if (username && username !== null) {
        const response = axios
          .post(
            `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`,
            {
              uuid: firebaseUser.uid,
              email: firebaseUser.email ? firebaseUser.email : "inboxdev.b@gmail.com",
              firstname: firebaseUser.displayName,
              // username: firebaseUser.displayName,
              username: username,
              status: "MN",
              profilepic: firebaseUser.photoURL,
              mobile:
                firebaseUser.phoneNumber !== null
                  ? parseInt(firebaseUser.phoneNumber)
                  : null,
            },
            {
              headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setUserCreated(true);          
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

//   curl --location 'https://cruncher.asset.money/user/mn/badge' \
// --header 'Content-Type: application/json' \
// --data '{
//     "userId": "Devesh",
// 	"artistName": "Ookay",
//     "artistId": "585281644",
//     "artistImage": "https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/3e/17/40/3e174077-c52e-ca40-f6be-9cd369f74f35/859726209705_cover.jpg/1000x1000ac.jpg",
// 	"level": 10,
//     "extraData": {
//         "right": 40,
//         "time": 87,
//         "percentile": 97,
//         "wallet": "0xb348799349CDB8abE74E209014d0987D66aA6fbD"
//     }
// }'
  const claimNft = async(userId, artistName, artistId, artistImage, level, right, time, percentile, wallet) => {
    try {

      const response = await axios.post(
        `https://cruncher.asset.money/user/mn/badge`,
        {
          userId: userId,
          artistName: artistName,
          artistId: artistId,
          artistImage: artistImage,
          level: level,
          extraData: {
            right: right,
            time: time,
            percentile: percentile,
            wallet: wallet
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      
      let data = response.data;
      if(data?.status === "ok") {
        return { success : true }
      } else {
        return { success : false }
      }
      
    } catch(err) {
      console.log(err.message)
      return { success : false }
    }
  }

  return {googleLogout, getUser, createUser, accessToken, userData, userCreated, userId, setAccessToken, setUserData,setUserCreated, setUser, setUserId, username, userInfo, claimNft}
}

export default useAsset;