import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import DefaultArtistPhoto from "../assets/images/Group 427320322.png";
import { IoIosSearch } from "react-icons/io";
import { searchArtist } from "../endpoints/api";
import { Link, useNavigate } from "react-router-dom";
import { replaceDimensionsInUrl } from "../common_functions/Common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { to } from "react-spring";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import Headphones from "../assets/images/Group 1000006010.svg";
import Photo1 from "../assets/images/billie-eilish-lrt-artist-chart-1ek-180x180.jpg";
import Photo2 from "../assets/images/bruno-mars-va7-180x180.jpg";
import Photo3 from "../assets/images/lana-del-rey-45t-artist-chart-ley-180x180.jpg";
import Photo4 from "../assets/images/rihanna-k0p-artistchart-5wb-180x180.jpg";
import Photo5 from "../assets/images/selena-gomez-lkr-artist-chart-tob-180x180.jpg";
import Photo9 from "../assets/images/drake-04g-180x180.jpg";
import Photo10 from "../assets/images/ed-sheeran-w3r-artist-chart-1li-180x180.jpg";
import Photo11 from "../assets/images/harry-styles-6jk-artistchart-rcm-180x180.jpg";
import Photo12 from "../assets/images/jungkook-is6-artistchart-ibg-180x180.jpg";
import Photo13 from "../assets/images/kanye-west-0wf-artist-chart-zee-180x180.jpg";
import Photo14 from "../assets/images/taylor-swift-9sy-artistchart-ko8-180x180.jpg";
import Photo15 from "../assets/images/olivia-rodrigo-3wl-artist-chart-9ct-180x180.jpg";
import Photo16 from "../assets/images/travis-scott-lu8-artistchart-xui-180x180.jpg";
import DummyImage from "../assets/images/dummyImage.png";
import { useDebounce } from "@uidotdev/usehooks";
import useAsset from "../hooks/useAsset";
import useClevertap from "../hooks/useClevertap";

interface UserData {
  photoURL: string;
  displayName: string;
}
interface UserInfo {
  username: string;
  bio: string;
  coverpic: string;
  created: number;
  email: string;
  firstname: string;
  followers: null;
  following: null;
  genre: [];
  lastname: string;
  mobile: number;
  profilepic: string;
  spotifytoken: string;
  status: string;
  telegramtoken: string;
  tokens: { applemusic: boolean; spotify: boolean; gmail: boolean };
  updated: number;
  uuid: string;
  wallet: string;
}

const Artist = () => {
  const images = [
    Photo9,
    Photo10,
    Photo11,
    Photo12,
    Photo5,
    Photo14,
    Photo15,
    Photo2,
    Photo3,
    Photo4,
    Photo13,
  ];
  const [artistName, setArtistName] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [artistId, setArtistId] = useState("");
  const [loader, setLoader] = useState(false);
  const [artistImage, setArtistImage] = useState("");
  const [artistFromApi, setArtistFromApi] = useState("");
  const [artists, setArtists] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const debouncedArtistName = useDebounce(artistName, 1000);

  const { userInfo: UserInfo, userData: UserData } = useAsset();
  const { handleEventPushClick } = useClevertap();
  const userInfo = UserInfo as unknown as UserInfo;
  const userData = UserData as unknown as UserData;
  const navigate = useNavigate();
  const posthog = usePostHog();

  async function indexArtist(artist_id: number | string) {
    try {
      if (artist_id) {
        const url = `${process.env.REACT_APP_ASSET_API}/music/artist/index/applemusic/${artist_id}`;
        // run async request for indexing
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleClick = () => {
    posthog?.capture("artist_selected", {
      artist_name: artistName,
      artist_id: artistId,
    });
    handleEventPushClick("MUSICNERD_ARTIST_SELECTED", {
      artist_name: artistName,
      artist_id: artistId,
      click: "Let's go",
    });
    navigate(`/quiz/${artistId}`, {
      state: {
        artistName: artistName,
        artistImage: artistImage,
      },
    });
  };
  const getArtistData = async (artistName: string) => {
    posthog?.capture("artist_searched", { artist_name: artistName });
    try {
      setLoader(true);
      setButtonEnabled(false);
      const response = await searchArtist(artistName);
      if (response.data.length !== 0) {
        setArtistId(response.data.data[0].artist_id);
        setArtistImage(response.data.data[0].artist_image);
        setArtistFromApi(response.data.data[0].artist_name);
        setArtists(response.data.data.slice(0, 5));
        setDropdownVisible(false);
        if (response?.data?.data[0]?.artist_id) {
          indexArtist(response?.data?.data[0]?.artist_id);
        }
      }
    } catch (e) {
      setButtonEnabled(true);
      setLoader(false);
      toast.error("The artist you entered does not exist", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("error");
    }
    setLoader(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // console.log("first", debouncedArtistName);
        const response = await searchArtist(debouncedArtistName);
        console.log("response", response);
        if (response && response.data && response.data.length !== 0) {
          setArtists(response.data.data.slice(0, 5));
        }
        setLoading(false);
      } catch (e) {
        console.error("Error fetching data:", e);
        setLoading(false);
      }
    };
    if (debouncedArtistName) {
      fetchData();
    }
  }, [debouncedArtistName]);

  const handleDropdownItemClick = (
    id: string,
    artistImage: string,
    artistName: string
  ) => {
    posthog?.capture("artist_selected", {
      artist_name: artistName,
      artist_id: id,
    });
    setButtonEnabled(false);
    setArtistId(id);
    setArtistImage(artistImage);
    setArtistName(artistName);
    setArtistFromApi(artistName);
    setDropdownVisible(false);
  };

  // console.log("artistImage user indor", userInfo, userData);

  return (
    <div className="flex flex-col w-screen overflow-x-hidden h-screen md:justify-between justify-center gap-7 md:gap-0 overflow-hidden items-center">
      {/* <Link className="flex flex-row gap-2 md:pt-10" to="/">
        <img src={Headphones} alt="headphones" className="w-8" />
        <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
          MUSIC NERD
        </span>
      </Link> */}
      <div className="flex justify-center items-center md:mt-16">
        <div className="flex md:justify-center absolute left-2 md:left-0 right-0">
          <Link className="flex flex-row gap-2" to="/">
            <img src={Headphones} alt="headphones" className="w-8" />
            <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
              MUSIC NERD
            </span>
          </Link>
        </div>
        {userData?.photoURL && (
          <div className="flex justify-end mr-5 md:mr-10 absolute right-0">
            <Link to="/profile">
              <img
                src={userData?.photoURL}
                alt=""
                className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-gray-800"
              />
            </Link>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center">
        <span className="md:text-3xl text-2xl md:px-0 px-7 font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk md:text-start text-center">
          {artistImage !== ""
            ? `${artistFromApi} is a great choice`
            : " Who's your favorite artist?"}
        </span>
        <span className="md:text-lg text-base md:px-0 px-10 text-[rgba(255,255,255,1)] cabinet-grotesk md:text-start text-center">
          We'll play a song by them, and you have 5 seconds to guess.
        </span>
        
      </div>
      {artistImage === "" ? (
        <div className="flex flex-row gap-5 mt-3">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`img-${index}`}
              className="md:w-32 md:h-44 w-28 h-36 rounded-3xl object-cover slide-left"
            />
          ))}
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default Artist;
