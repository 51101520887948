export function replaceDimensionsInUrl(url: string) {
  const lastSlashIndex = url?.lastIndexOf("/");
  if (
    lastSlashIndex !== -1 &&
    (url?.includes("{w}x{h}bb.jpg") || url?.includes("{w}x{h}ac.jpg"))
  ) {
    const baseUrl = url?.substring(0, lastSlashIndex);
    const newUrl = `${baseUrl}/200x200bb.jpg`;
    return newUrl;
  }
  return url;
}

// const originalUrl = 'https://example.com/images/{w}x{h}bb.jpg';
// const modifiedUrl = replaceDimensionsInUrl(originalUrl);
// console.log(modifiedUrl);
